import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const CategoryList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['KATEGORİ ADI', 'KATEGORİ LİNKİ'];

  const onDelete = async id => {
    api.category.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.category
      .list(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/category/add')}
      >
        Yeni Kategori Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="category"
        pageNumber={page}
        onDelete={onDelete}
        hideRows={`__v,_id,createdAt,updatedAt`}
      />
    </main>
  );
};

export default CategoryList;
