import { AppButton, AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import api from 'services/api';

const SiteList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page] = useState(
    searchParams.get('page') ? Number(searchParams.get('page')) : 1
  );
  const titles = ['SİTE ADI', 'SİTE LOGOSU'];

  const onDelete = async id => {
    api.site.delete(id).then(() => {
      navigate(0);
    });
  };

  useEffect(() => {
    api.site
      .list(page)
      .then(async res => {
        setData(res.data);
      })
      .catch(error => console.log('error', error));
  }, []);

  return (
    <main className="w-full">
      <AppButton
        className="mb-5 ml-auto"
        isFullWidth={false}
        onClick={() => navigate('/site/add')}
      >
        Yeni Site Ekle
      </AppButton>
      <AppTable
        titles={titles}
        rows={data}
        isEdit={true}
        isDelete={true}
        pageKey="site"
        pageNumber={page}
        onDelete={onDelete}
        hideRows={`__v,_id,createdAt,updatedAt,slug,bonus,bonusEn,terms,termsEn,link,rated,ratedCount,claimedCount,payments,averagePayout,currencies,
        advantage_1_title,
        advantage_1_titleEn,
        advantage_1_status,
        advantage_2_title,
        advantage_2_titleEn,
        advantage_2_status,
        advantage_3_title,
        advantage_3_titleEn,
        advantage_3_status,
        advantage_4_title,
        advantage_4_titleEn,
        advantage_4_status,
        advantage_5_title,
        advantage_5_titleEn,
        advantage_5_status,
        advantage_6_title,
        advantage_6_titleEn,
        advantage_6_status`}
      />
    </main>
  );
};

export default SiteList;
