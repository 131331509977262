import React from 'react';
import { FormErrorMessage } from 'components';

const AppInput = ({
  label,
  name,
  placeholder,
  register,
  error,
  rules,
  type = 'text',
  ...props
}) => {
  return (
    <div className="w-full mb-5">
      <label
        htmlFor={name}
        className={`w-full block mb-2 text-sm font-medium ${
          error ? 'text-red-600' : 'text-gray-900'
        }`}
      >
        {label}
      </label>
      <input
        type={type}
        id={name}
        className={`w-full bg-gray-50 border-2 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-yellow-500 block p-2.5 ${
          error
            ? 'border-red-500 focus:border-red-500'
            : 'border-gray-300 focus:border-gray-700'
        }`}
        placeholder={placeholder}
        {...register(name, rules)}
        {...props}
      />
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppInput;
