import React from 'react';
import { Widget } from '@uploadcare/react-widget';
import { t } from 'locales';

const AppFile = ({
  label,
  error,
  crop,
  onCompleted,
  multiple = false,
  multipleMax = 7
}) => {
  const UPLOADCARE_PUBLIC_KEY =
    process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey';
  return (
    <div className="w-full mb-5">
      <label
        htmlFor="appFile"
        className={`w-full block mb-2 text-sm font-medium ${
          error ? 'text-red-600' : 'text-gray-900'
        }`}
      >
        {label || t('_general.image')}
      </label>
      <Widget
        publicKey={UPLOADCARE_PUBLIC_KEY}
        onChange={onCompleted}
        imagesOnly
        locale="tr"
        effects={'crop'}
        crop={crop || '16:9'}
        multiple={multiple}
        multipleMax={multipleMax}
      />
    </div>
  );
};

export default AppFile;
