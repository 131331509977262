import React from 'react';
import { AuthLayout, DashboardLayout } from '../layouts';
import Home from './Home';
import Login from './Login';
import Page404 from './Page404';
import TeamList from './Teams/TeamList';
import AddTeam from './Teams/AddTeam';
import LeagueList from './Leagues/LeagueList';
import AddLeague from './Leagues/AddLeague';
import CategoryList from './Categories/CategoryList';
import AddCategory from './Categories/AddCategory';
import Deploy from './Deploy';
import AddSite from './Sites/AddSite';
import SiteList from './Sites/SiteList';
import AddCountry from './Countries/AddCountry';
import CountryList from './Countries/CountryList';
import PaymentList from './Payments/PaymentList';
import AddPayment from './Payments/AddPayment';
import SlideList from './Slides/SlideList';
import AddSlide from './Slides/AddSlide';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'deploy',
        element: <Deploy />
      },
      {
        path: 'team',
        children: [
          {
            path: 'list',
            element: <TeamList />
          },
          {
            path: 'add',
            element: <AddTeam />
          }
        ]
      },
      {
        path: 'league',
        children: [
          {
            path: 'list',
            element: <LeagueList />
          },
          {
            path: 'add',
            element: <AddLeague />
          }
        ]
      },
      {
        path: 'category',
        children: [
          {
            path: 'list',
            element: <CategoryList />
          },
          {
            path: 'add',
            element: <AddCategory />
          }
        ]
      },
      {
        path: 'site',
        children: [
          {
            path: 'list',
            element: <SiteList />
          },
          {
            path: 'add',
            element: <AddSite />
          }
        ]
      },
      {
        path: 'country',
        children: [
          {
            path: 'list',
            element: <CountryList />
          },
          {
            path: 'add',
            element: <AddCountry />
          }
        ]
      },
      {
        path: 'payment',
        children: [
          {
            path: 'list',
            element: <PaymentList />
          },
          {
            path: 'add',
            element: <AddPayment />
          }
        ]
      },
      {
        path: 'slide',
        children: [
          {
            path: 'list',
            element: <SlideList />
          },
          {
            path: 'add',
            element: <AddSlide />
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  },
  {
    path: '*',
    element: <Page404 />
  }
];

export default routes;
