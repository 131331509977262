import { AppButton } from 'components';
import { t } from 'locales';
import React, { useState } from 'react';
import deployApi from 'lib/deploy';

export default function Home() {
  const [isDeploying, setIsDeploying] = useState(false);

  const startDeploy = async () => {
    setIsDeploying(true);
    await deployApi().then(res => {
      if (res) {
        setTimeout(() => {
          setIsDeploying(false);
        }, 60 * 1000 * 4);
      }
    });
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto text-center">
      <div className="text-3xl font-bold text-gray-700 mb-4">
        {t('_deployPage.title')}
      </div>
      <div className="text-lg text-gray-700 mb-8">
        {t('_deployPage.subtitle')}
      </div>
      <ul className="list-disc text-left mb-10 text-gray-600">
        <li>{`${t('_deployPage.time')} : 2 ${t('_deployPage.minute')}`}</li>
        <li>
          {t('_deployPage.status')} :{' '}
          {t(`_deployPage.${isDeploying ? 'statusPending' : 'statusSuccess'}`)}
        </li>
      </ul>

      <AppButton
        className="mb-2"
        isLoading={isDeploying}
        onClick={() => startDeploy('tr')}
      >
        {t(`_deployPage.${isDeploying ? 'deploying' : 'deploy'}`)}
      </AppButton>
    </main>
  );
}
