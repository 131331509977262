import React, { useEffect, useMemo } from 'react';
import { AppButton, AppInput, AppSelect } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [sites, setSites] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      const sitesData = [{ value: null, label: 'Seçiniz' }];
      await api.site.list(0).then(async res => {
        res.data.map(site => {
          sitesData.push({ value: site._id, label: site.name });
        });
        setSites(sitesData);
      });
      const categoriesData = [{ value: null, label: 'Seçiniz' }];
      await api.category.list(0).then(async res => {
        res.data.map(category => {
          categoriesData.push({ value: category._id, label: category.name });
        });
        setCategories(categoriesData);
      });
      if (isEdit) {
        await api.country.get(id).then(async res => {
          const data = res.data;
          const editData = {
            name: data.name,
            nameEn: data.nameEn,
            code: data.code,
            category: categoriesData.find(
              category => category.value === data.category
            ),
            site_1: sitesData.find(site => site.value === data.site_1),
            site_2: sitesData.find(site => site.value === data.site_2),
            site_3: sitesData.find(site => site.value === data.site_3),
            site_4: sitesData.find(site => site.value === data.site_4),
            site_5: sitesData.find(site => site.value === data.site_5),
            site_6: sitesData.find(site => site.value === data.site_6),
            site_7: sitesData.find(site => site.value === data.site_7),
            site_8: sitesData.find(site => site.value === data.site_8),
            site_9: sitesData.find(site => site.value === data.site_9),
            site_10: sitesData.find(site => site.value === data.site_10)
          };
          setData(editData);
          reset(editData);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.country[isEdit ? 'update' : 'create'](
      submitData.name,
      submitData.nameEn,
      submitData.code,
      submitData.category.value,
      submitData.site_1.value,
      submitData.site_2?.value,
      submitData.site_3?.value,
      submitData.site_4?.value,
      submitData.site_5?.value,
      submitData.site_6?.value,
      submitData.site_7?.value,
      submitData.site_8?.value,
      submitData.site_9?.value,
      submitData.site_10?.value,
      id
    )
      .then(async () => {
        navigate('/country/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Ülke Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni ülke eklemek için aşağıdaki formu doldurun. Ülke eklerken site
        sıralamarını da ekleyebilirsiniz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Ülke Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{}}
          register={register}
          placeholder="Ülke Adı"
        />
        <AppInput
          label="Ülke Adı (İngilizce)"
          name="nameEn"
          // @ts-ignore
          error={errors.nameEn}
          rules={{}}
          register={register}
          placeholder="Ülke Adı (İngilizce)"
        />
        <AppInput
          label="Ülke Kodu"
          name="code"
          // @ts-ignore
          error={errors.code}
          rules={{}}
          register={register}
          placeholder="Ülke Kodu"
        />
        <AppSelect
          options={categories}
          label="Kategori"
          name="category"
          error={errors.category}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />
        <AppSelect
          options={sites}
          label="1. Site"
          name="site_1"
          error={errors.site_1}
          rules={{ required: true }}
          control={control}
          placeholder="1. Site"
        />
        <AppSelect
          options={sites}
          label="2. Site"
          name="site_2"
          error={errors.site_2}
          control={control}
          placeholder="2. Site"
        />
        <AppSelect
          options={sites}
          label="3. Site"
          name="site_3"
          error={errors.site_3}
          control={control}
          placeholder="3. Site"
        />
        <AppSelect
          options={sites}
          label="4. Site"
          name="site_4"
          error={errors.site_4}
          control={control}
          placeholder="4. Site"
        />
        <AppSelect
          options={sites}
          label="5. Site"
          name="site_5"
          error={errors.site_5}
          control={control}
          placeholder="5. Site"
        />
        <AppSelect
          options={sites}
          label="6. Site"
          name="site_6"
          error={errors.site_6}
          control={control}
          placeholder="6. Site"
        />
        <AppSelect
          options={sites}
          label="7. Site"
          name="site_7"
          error={errors.site_7}
          control={control}
          placeholder="7. Site"
        />
        <AppSelect
          options={sites}
          label="8. Site"
          name="site_8"
          error={errors.site_8}
          control={control}
          placeholder="8. Site"
        />
        <AppSelect
          options={sites}
          label="9. Site"
          name="site_9"
          error={errors.site_9}
          control={control}
          placeholder="9. Site"
        />
        <AppSelect
          options={sites}
          label="10. Site"
          name="site_10"
          error={errors.site_10}
          control={control}
          placeholder="10. Site"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
