import React, { useEffect, useMemo } from 'react';
import { AppButton, AppFile, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function AddSlide() {
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    if (isEdit) {
      api.slide.get(id).then(async res => {
        setData(res.data);
        setImage(res.data.image);
        reset(res.data);
      });
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.slide[isEdit ? 'update' : 'create'](
      submitData.title,
      submitData.description,
      submitData.link,
      image,
      id
    )
      .then(async () => {
        navigate('/slide/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Slide Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni slide eklemek için aşağıdaki formu doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Slide Başlığı"
          name="title"
          // @ts-ignore
          error={errors.title}
          rules={{ required: true }}
          register={register}
          placeholder="Slide Başlığı"
        />
        <AppInput
          label="Slide Açıklaması"
          name="description"
          // @ts-ignore
          error={errors.description}
          rules={{ required: true }}
          register={register}
          placeholder="Slide Açıklaması"
        />
        <AppInput
          label="Slide Linki"
          name="link"
          // @ts-ignore
          error={errors.link}
          rules={{ required: true }}
          register={register}
          placeholder="Slide Linki"
        />
        <img src={image} className="w-16 h-auto mt-5 bg-gray-300" />
        <AppFile
          error=""
          label="Slide Resmi"
          onCompleted={file => setImage(file.cdnUrl)}
          crop="auto"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Kaydet
        </AppButton>
      </form>
    </main>
  );
}
