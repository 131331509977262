import React, { useEffect, useMemo } from 'react';
import {
  AppButton,
  AppCheckbox,
  AppFile,
  AppInput,
  AppSelect
} from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from 'utils/slug';

export default function AddSite() {
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;
  const [payments, setPayments] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    const init = async () => {
      let paymentsData = [];
      await api.payment.list().then(res => {
        paymentsData = res.data;
        setPayments(res.data);
      });
      if (isEdit) {
        api.site.get(id).then(async res => {
          const paymentIds = res.data.payments || [];
          paymentsData.forEach(payment => {
            if (paymentIds.includes(payment._id)) {
              res.data[`payment-${payment._id}`] = true;
            }
          });
          const advantageStatusOptions = [
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ];
          for (let i = 1; i < 7; i++) {
            res.data[`advantage_${i}_status`] = advantageStatusOptions.find(
              option => option.value === res.data[`advantage_${i}_status`]
            );
          }
          setData(res.data);
          setLogo(res.data.logo);
          reset(res.data);
        });
      }
    };
    init();
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    const paymentIds = [];
    payments.forEach(payment => {
      if (submitData[`payment-${payment._id}`]) {
        paymentIds.push(payment._id);
      }
    });

    await api.site[isEdit ? 'update' : 'create'](
      submitData.name,
      convertToSlug(submitData.name),
      logo,
      submitData.rated,
      submitData.ratedCount,
      submitData.claimedCount,
      submitData.bonus,
      submitData.bonusEn,
      submitData.terms,
      submitData.termsEn,
      submitData.link,
      paymentIds,
      submitData.averagePayout,
      submitData.currencies,
      submitData.advantage_1_title,
      submitData.advantage_1_titleEn,
      submitData.advantage_1_status?.value,
      submitData.advantage_2_title,
      submitData.advantage_2_titleEn,
      submitData.advantage_2_status?.value,
      submitData.advantage_3_title,
      submitData.advantage_3_titleEn,
      submitData.advantage_3_status?.value,
      submitData.advantage_4_title,
      submitData.advantage_4_titleEn,
      submitData.advantage_4_status?.value,
      submitData.advantage_5_title,
      submitData.advantage_5_titleEn,
      submitData.advantage_5_status?.value,
      submitData.advantage_6_title,
      submitData.advantage_6_titleEn,
      submitData.advantage_6_status?.value,
      id
    )
      .then(async () => {
        navigate('/site/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Site Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir site eklemek için aşağıdaki formu doldurun. Lütfen tüm alanları
        eksiksiz doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label="Site Adı"
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder="Site Adı"
        />
        <img src={logo} className="w-16 h-auto mt-5 bg-gray-300" />
        <AppFile
          error=""
          label="Site Logosu"
          onCompleted={file => setLogo(file.cdnUrl)}
          crop="auto"
        />
        <AppInput
          label="Puanı"
          name="rated"
          // @ts-ignore
          error={errors.rated}
          rules={{ required: true }}
          register={register}
          placeholder="Puanı"
        />
        <AppInput
          label="Oylayan Kişi Sayısı"
          name="ratedCount"
          // @ts-ignore
          error={errors.ratedCount}
          rules={{ required: true }}
          register={register}
          placeholder="Oylayan Kişi Sayısı"
        />
        <AppInput
          label="Kullanan Kişi Sayısı"
          name="claimedCount"
          // @ts-ignore
          error={errors.claimedCount}
          rules={{ required: true }}
          register={register}
          placeholder="Kullanan Kişi Sayısı"
        />
        <AppInput
          label="Site Linki"
          name="link"
          // @ts-ignore
          error={errors.link}
          rules={{ required: true }}
          register={register}
          placeholder="Site Linki"
        />
        <AppInput
          label="Bonus"
          name="bonus"
          // @ts-ignore
          error={errors.bonus}
          rules={{ required: true }}
          register={register}
          placeholder="Bonus"
        />
        <AppInput
          label="Bonus (İngilizce)"
          name="bonusEn"
          // @ts-ignore
          error={errors.bonusEn}
          rules={{ required: true }}
          register={register}
          placeholder="Bonus (İngilizce)"
        />
        <AppInput
          label="Koşullar"
          name="terms"
          // @ts-ignore
          error={errors.terms}
          rules={{ required: true }}
          register={register}
          placeholder="Koşullar"
        />
        <AppInput
          label="Koşullar (İngilizce)"
          name="termsEn"
          // @ts-ignore
          error={errors.termsEn}
          rules={{ required: true }}
          register={register}
          placeholder="Koşullar (İngilizce)"
        />
        <div className="w-full block mb-2 text-sm font-medium text-gray-900">
          Ödeme Yöntemleri
        </div>
        <div className="flex flex-wrap">
          {payments.map((payment, index) => (
            <AppCheckbox
              isFullWidth={false}
              key={index}
              label={payment.name}
              name={`payment-${payment._id}`}
              register={register}
            />
          ))}
        </div>
        <AppInput
          label="Ortalama Ödeme Süresi"
          name="averagePayout"
          // @ts-ignore
          error={errors.averagePayout}
          rules={{}}
          register={register}
          placeholder="Ortalama Ödeme Süresi"
        />
        <AppInput
          label="Para Birimleri"
          name="currencies"
          // @ts-ignore
          error={errors.currencies}
          rules={{}}
          register={register}
          placeholder="Para Birimleri"
        />
        <AppInput
          label="1. Avantaj"
          name="advantage_1_title"
          // @ts-ignore
          error={errors.advantage_1_title}
          rules={{}}
          register={register}
          placeholder="1. Avantaj"
        />
        <AppInput
          label="1. Avantaj (İngilizce)"
          name="advantage_1_titleEn"
          // @ts-ignore
          error={errors.advantage_1_titleEn}
          rules={{}}
          register={register}
          placeholder="1. Avantaj (İngilizce)"
        />
        <AppSelect
          label="1. Avantaj Durumu"
          name="advantage_1_status"
          // @ts-ignore
          error={errors.advantage_1_status}
          rules={{}}
          control={control}
          placeholder="1. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppInput
          label="2. Avantaj"
          name="advantage_2_title"
          // @ts-ignore
          error={errors.advantage_2_title}
          rules={{}}
          register={register}
          placeholder="2. Avantaj"
        />
        <AppInput
          label="2. Avantaj (İngilizce)"
          name="advantage_2_titleEn"
          // @ts-ignore
          error={errors.advantage_2_titleEn}
          rules={{}}
          register={register}
          placeholder="2. Avantaj (İngilizce)"
        />
        <AppSelect
          label="2. Avantaj Durumu"
          name="advantage_2_status"
          // @ts-ignore
          error={errors.advantage_2_status}
          rules={{}}
          control={control}
          placeholder="2. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppInput
          label="3. Avantaj"
          name="advantage_3_title"
          // @ts-ignore
          error={errors.advantage_3_title}
          rules={{}}
          register={register}
          placeholder="3. Avantaj"
        />
        <AppInput
          label="3. Avantaj (İngilizce)"
          name="advantage_3_titleEn"
          // @ts-ignore
          error={errors.advantage_3_titleEn}
          rules={{}}
          register={register}
          placeholder="3. Avantaj (İngilizce)"
        />
        <AppSelect
          label="3. Avantaj Durumu"
          name="advantage_3_status"
          // @ts-ignore
          error={errors.advantage_3_status}
          rules={{}}
          control={control}
          placeholder="3. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppInput
          label="4. Avantaj"
          name="advantage_4_title"
          // @ts-ignore
          error={errors.advantage_4_title}
          rules={{}}
          register={register}
          placeholder="4. Avantaj"
        />
        <AppInput
          label="4. Avantaj (İngilizce)"
          name="advantage_4_titleEn"
          // @ts-ignore
          error={errors.advantage_4_titleEn}
          rules={{}}
          register={register}
          placeholder="4. Avantaj (İngilizce)"
        />
        <AppSelect
          label="4. Avantaj Durumu"
          name="advantage_4_status"
          // @ts-ignore
          error={errors.advantage_4_status}
          rules={{}}
          control={control}
          placeholder="4. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppInput
          label="5. Avantaj"
          name="advantage_5_title"
          // @ts-ignore
          error={errors.advantage_5_title}
          rules={{}}
          register={register}
          placeholder="5. Avantaj"
        />
        <AppInput
          label="5. Avantaj (İngilizce)"
          name="advantage_5_titleEn"
          // @ts-ignore
          error={errors.advantage_5_titleEn}
          rules={{}}
          register={register}
          placeholder="5. Avantaj (İngilizce)"
        />
        <AppSelect
          label="5. Avantaj Durumu"
          name="advantage_5_status"
          // @ts-ignore
          error={errors.advantage_5_status}
          rules={{}}
          control={control}
          placeholder="5. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppInput
          label="6. Avantaj"
          name="advantage_6_title"
          // @ts-ignore
          error={errors.advantage_6_title}
          rules={{}}
          register={register}
          placeholder="6. Avantaj"
        />
        <AppInput
          label="6. Avantaj (İngilizce)"
          name="advantage_6_titleEn"
          // @ts-ignore
          error={errors.advantage_6_titleEn}
          rules={{}}
          register={register}
          placeholder="6. Avantaj (İngilizce)"
        />
        <AppSelect
          label="6. Avantaj Durumu"
          name="advantage_6_status"
          // @ts-ignore
          error={errors.advantage_6_status}
          rules={{}}
          control={control}
          placeholder="6. Avantaj Durumu"
          options={[
            { label: 'Avantaj', value: true },
            { label: 'Dezavantaj', value: false }
          ]}
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
