import React from 'react';

const Home = () => {
  return (
    <div>
      <h2 className="mb-2 text-lg font-semibold text-gray-900">
        Admin Kullanım Kılavuzu
      </h2>
      <ul className="space-y-1 list-disc list-inside text-gray-500 ">
        <li>
          Sol menüde yer alan Takımlar sekmesinde ile istediğiniz takımı
          ekleyebilirsiniz. Takım eklerken herhangi bir lig ekleme zorunluluğu
          yoktur.
        </li>
        <li>
          Ligler sekmesiyle istenilen lig ve kupa oluşturulabilir. Şampiyonlar
          Ligi, Dünya Kupası gibi organizasyonlar da oluşturulabilir.
        </li>
        <li>
          Maçlar ile oynanacak maçlar belirlenebilir. Lig seçilmesi zorunludur.
          Ayrıca maçta eklenen saat Türkiye saatine göre eklenmektedir.
        </li>
        <li>
          Lig eklerken haftanın başlığını sürekli olarak düzenleyebilirsiniz.
          Örnek olarak 18. Hafta tamamlandıktan sonra Düzenle sekmesinden girip
          ilgili ligi 19. Hafta yapabilirsiniz.
        </li>
        <li>
          Takım eklerken Teknik Direktör fotoğrafını PNG formatta eklemeye özen
          gösterin. Diğer türlü istenilen formatta resim gösterilmeyecektir.
        </li>
        <li>
          Deploy sekmesiyle birlikte yapılan geliştirmeler yayına
          gönderilmektedir. Ortalama 5 dakika sürmektedir. Yapılacak
          değişikliklerde bu süreyi hesap ediniz.
        </li>
      </ul>
    </div>
  );
};

export default Home;
