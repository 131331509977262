import React, { useEffect, useMemo } from 'react';
import { AppButton, AppFile, AppInput } from 'components';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { t } from 'locales';
import api from 'services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToSlug } from 'utils/slug';

export default function AddLeague() {
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [desktopCover, setDesktopCover] = useState();
  const [mobileCover, setMobileCover] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const id = searchParams.get('id');
  const isEdit = !!id;

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: useMemo(() => {
      return data;
    }, [data])
  });

  useEffect(() => {
    if (isEdit) {
      api.league.get(id).then(async res => {
        setData(res.data);
        setLogo(res.data.logo);
        setDesktopCover(res.data.desktopCover);
        setMobileCover(res.data.mobileCover);
        reset(res.data);
      });
    }
  }, []);

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.league[isEdit ? 'update' : 'create'](
      submitData.name,
      submitData.nameEn,
      logo,
      submitData.weekName,
      submitData.weekNameEn,
      desktopCover,
      mobileCover,
      convertToSlug(submitData.name),
      convertToSlug(submitData.nameEn),
      submitData.order,
      id
    )
      .then(async () => {
        navigate('/league/list');
      })
      .catch(error => console.log('error', error));
    setIsLoading(false);
  };

  return (
    <main className="w-2/5 flex flex-col items-center mx-auto">
      <div className="text-3xl font-bold text-gray-700 mb-4 text-center">
        Yeni Lig Ekle
      </div>
      <div className="text-lg text-gray-700 mb-8 text-center">
        Yeni bir lig ekleyebilir ve bu lige maçlar ekleyebilirsiniz. Lütfen tüm
        alanları eksiksiz doldurunuz.
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_leagues.name')}
          name="name"
          // @ts-ignore
          error={errors.name}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_leagues.name')}
        />
        <AppInput
          label={t('_leagues.nameEn')}
          name="nameEn"
          // @ts-ignore
          error={errors.nameEn}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_leagues.nameEn')}
        />
        <img src={logo} className="w-16 h-auto mt-5 bg-gray-300" />
        <AppFile
          error=""
          label="Lig Logosu"
          onCompleted={file => setLogo(file.cdnUrl)}
          crop="auto"
        />
        <img src={desktopCover} className="w-full h-auto mt-5" />
        <AppFile
          error=""
          label="Lig Kapak Fotoğrafı - Masaüstü"
          onCompleted={file => setDesktopCover(file.cdnUrl)}
          crop="auto"
        />
        <img src={mobileCover} className="w-full h-auto mt-5" />
        <AppFile
          error=""
          label="Lig Kapak Fotoğrafı - Mobil"
          onCompleted={file => setMobileCover(file.cdnUrl)}
          crop="auto"
        />
        <AppInput
          label={t('_leagues.weekName')}
          name="weekName"
          // @ts-ignore
          error={errors.weekName}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_leagues.weekName')}
        />
        <AppInput
          label={t('_leagues.weekNameEn')}
          name="weekNameEn"
          // @ts-ignore
          error={errors.weekNameEn}
          rules={{ required: true, minLength: 3 }}
          register={register}
          placeholder={t('_leagues.weekNameEn')}
        />
        <AppInput
          label="Sıra"
          name="order"
          // @ts-ignore
          error={errors.order}
          rules={{}}
          register={register}
          placeholder="Sıra"
        />
        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          {t('_general.save')}
        </AppButton>
      </form>
    </main>
  );
}
